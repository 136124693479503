








































































import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util';

@Component({
    components: { FormBase },
})
export default class ApplicantsList extends ListBase {
    @Prop({ type: Boolean, default: false }) serverSave: boolean;
    model: any = { name: null };
    sState: any = Util.abp.list.getCodeList('E_ELECTION');
    
    get list() {
        return this.$store.state.election.applicants;
    }
    get totalList() {
        if (this.$store.state.election.applicants == undefined) return 0;
        return this.$store.state.election.applicants.length;
    }
    get loading() {
        return this.$store.state.election.loading;
    }

    get appLoading() {
        return this.$store.state.election.appLoading;
    };         
    get election() {
        return this.$store.state.election.editModel;
    }

    get isCanceled() {
        return this.election.state_id == this.sState.AN;
    }

    get isFinished() {
        return this.election.state_id == this.sState.CE;
    }
    
    itemsSearch = [
        this.gItem({ type: 'text', prop: 'name', label: 'Ingrese un Pregunta', preicon:'question_answer', size: 8, hideDetails: true }),
    ]
    
    async addItem() {
        if (!this.serverSave) {
            await this.$store.commit('election/addItem', this.model.name);
        } else {
            await this.$store.dispatch({
                type: 'election/createApplicant',
                data: {
                    election_id: this.election.id,
                    name: this.model.text,
                }
            });
        }
        this.model.name = null;
    }

    async updateItem(item) {
        if (item.id > 0 && this.serverSave) {
            await this.$store.dispatch({
                type: 'election/updateApplicant',
                data: item
            });
        }
    }


    async deleteItem(index) {
        if (!this.serverSave) {
            this.$store.commit('election/deleteApplicant', index);
        } else {
            await this.$store.dispatch({
                type: 'election/deleteApplicant',
                data: this.list[index]
            });
            await this.$store.dispatch({
                type: 'election/getApplicants',
                data: { election: this.election.id }
            });
        }
    }

    created() {

    }

    mounted() {

    }
}